/*----------------------------------------*\
  GRID
  Modern grid using flexbox
\*----------------------------------------*/

.#{$namespace}grid,
%grid {
  display: flex;
  flex-wrap: wrap;
  #{$margin-left}: -$spacing-unit-default;
}

  .#{$namespace}grid__item,
  %grid__item {
    width: 100%;
    flex: 0 0 auto;
    #{$padding-left}: $spacing-unit-default;
  }


/**
 * Alignment
 */

.#{$namespace}grid--center,
%grid--center {
  justify-content: center;
}

.#{$namespace}grid--right,
%grid--right {
  justify-content: flex-end;
}

.#{$namespace}grid--middle,
%grid--middle {
  align-items: center;
}

.#{$namespace}grid--bottom,
%grid--bottom {
  align-items: flex-end;
}

.#{$namespace}grid--even,
%grid--even {
  > .grid__item {
    display: flex;
    flex-direction: column;

    > * {
      flex: 1 0 auto;
    }
  }
}


/**
 * Order
 */

.#{$namespace}grid--rev,
%grid--rev {
  flex-direction: row-reverse;
}


/**
 * Gutters size
 */

@include foreach-alternative-spacing() {
  .#{$namespace}grid--#{$spacing-name},
  %grid--#{$spacing-name} {
    #{$margin-left}: -#{$spacing-value};

    > .#{$namespace}grid__item {
      #{$padding-left}: $spacing-value;
    }
  }
}


/**
 * Add spacing between rows
 */

.#{$namespace}grid--multiline,
%grid--multiline {
  margin-top: -$spacing-unit-default;

  > .#{$namespace}grid__item {
    padding-top: $spacing-unit-default;
  }

  @include foreach-alternative-spacing() {
    &.#{$namespace}grid--#{$spacing-name} {
      margin-top: -#{$spacing-value};

      > .#{$namespace}grid__item {
        padding-top: $spacing-value;
      }
    }
  }
}
