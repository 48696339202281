$brand-color: #63AB62;
$secondary-brand-color: #8CDD81;
$middle-gradient-color: mix($brand-color, $secondary-brand-color, 95%);

// Breakpoints
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 640px)";
$desktop: "(min-width: 768px)";

$tablet-width: 768px;
$desktop-width: 1024px;

@mixin phone {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet-min {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}