/*----------------------------------------*\
  TABLE
\*----------------------------------------*/

table {
  width: 100%;
  margin-bottom: $spacing-unit-default;

  border-collapse: collapse;
}

caption {
  text-align: flip(left, right);
  font-style: italic;
}

th {
  text-align: flip(left, right);
  font-weight: bold;
}

th,
td {
  padding: $spacing-unit-tiny;
}
