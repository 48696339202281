@import "../assets/js/klaro/dist/klaro.css";

@media (min-width: 990px) {
  .cookie-notice {
    max-width: 85%!important;
  }
}
.klaro {
  .cookie-modal .cm-modal,
  .cookie-modal .cm-modal.cm-klaro,
  .cookie-notice,
  .cookie-notice:not(.cookie-modal-notice) {
    background-color: lighten(#2b2b40, 10%);
    a {
      color: $brand-color;
    }
    .cm-btn {
      cursor: pointer;
      padding: 0.7em;
      &.cm-btn-sm {
        padding: 0.5em;
        font-size: 1.1em;
      }
    }
    .cm-btn.cm-btn-accept,
    .cm-btn.cm-btn-success {
      background: $brand-color;
    }
    .hide {
      cursor: pointer;
    }
  }

  .cookie-notice .cm-app-input:checked + .cm-app-label .active,
  .cookie-modal .cm-app-input:checked + .cm-app-label .active {
    background-color: $brand-color;
  }
  .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app.cm-toggle-all {
    display: none;
  }
}