@import "variables";

.nucose-dialog {

  h1 {
    text-align: center;
    font-family: Tahoma, Arial, sans-serif;
    color: $brand-color;
    margin: 80px 0;
  }

  .footer {
    background-color: #2b2b40;
  }

  .box {
    text-align: center;
    display: flex;
    justify-content: flex-end;
  }

  .button {
    font-size: 1em;
    padding: 10px;
    margin-left: 1em;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .button:hover {
    background: $brand-color;
    color: #fff;
  }
  .button.cancelText:hover {
    background: #999;
    color: #fff;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: visible;
    opacity: 1;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }

  .popup {
    top: 20%;
    margin: 70px auto;
    background: #fff;
    border-radius: 5px;
    width: 70%;
    position: relative;
    transition: all 5s ease-in-out;
  }

  .popup h2 {
    background-color: #999;
    margin-top: 0;
    color: #333;
    //font-family: Tahoma, Arial, sans-serif;
    padding: 1em;
    border-radius: 5px;
  }
  .popup .close {
    position: absolute;
    top: 10px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    color: $brand-color;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
    padding: 1em 3em 2em;
  }
}

@include phone {
  .nucose-dialog .popup {
    top: 10%;
    width: 100%;
    .content {
      max-height: 300px;
      padding: 1em 2em 2em;
    }
  }
}