html {
  background: #2b2b40;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Raleway", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
}
body {
  &.font-qs {
    font-family: "Quicksand", sans-serif;
    font-size: 1.1rem;
    font-weight: normal;
  }
  &.font-karla {
    font-family: "Karla", sans-serif;
    font-size: 1.1rem;
  }
  &.font-aleo {
    font-family: "Aleo", serif;
  }
  &.font-fs {
    font-family: "Fira Sans", sans-serif;
  }
  &.font-jfs {
    font-family: "Josefin Sans", sans-serif;
  }
  &.font-lvc {
    font-family: "Livvic", sans-serif;
  }
  &.font-ops {
    font-family: "Overpass", sans-serif;
  }
  &.font-oxy {
    font-family: "Oxygen", sans-serif;
  }
}
