
a {
	color: $brand-color;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

img {
	width: 100%;
}

strong {
	font-weight: bold;
}

h1 strong, h2 strong {
	font-weight: 700;
}

h1 {
	font-weight: 300;
	font-size: 2.3em;
	margin: 0;
}

h2 {
	font-weight: 300;
	font-size: 2.2em;
	margin: 0 0 30px 0;
}

h3 {
	margin: 20px 0 10px 0;
}


address {
	font-size: 1.38em;
	color: #666;
	margin-bottom: 20px;
	font-weight: 300;
	line-height: 1.4em;
}