@import "font.aleo.css";
@import "font.fira-sans.css";
@import "font.josefine-sans.css";
@import "font.karla.css";
@import "font.livvic.css";
@import "font.overpass.css";
@import "font.oxygen.css";
@import "font.quicksand.css";
@import "font.raleway.css";

@import "mixins/flexbox";
@import "mixins/columns";
@import "variables";
@import "base";
@import "deprecated";
@import "landing-page";
@import "layout";

//@import "../node_modules/bulma/sass/utilities/_all";
//@import "../node_modules/bulma/sass/base/_all";
//@import "../node_modules/bulma/sass/components/card";
//@import '../node_modules/buefy/src/scss/components/modal';
//@import '../node_modules/buefy/src/scss/components/dialog';
@import "cookie.klaro";
@import "../node_modules/kanbasu/src/scss/kanbasu";
@import "flex";
@import "forms";
@import "button";
@import "elements";

@import "modal";
@import "pricing";
@import "staff";
@import "contact";
@import "privacy";
@import "blog";
@import "navigation";
@import "footer";
@import "cloudcannon";
@import "not-found";