@import "flex.scss";

.flex-buttons {
  @extend .flex;
  justify-content: space-evenly;
  .lbn-min {
    margin: 4em 0;
    min-width: 20em;
    a {
      display: block;
      color: #fff;
      background: rgba(255, 255, 255, 0.15);
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 3px;
      padding: 1em 3em;
      text-decoration: none;
      transition: .2s ease-in-out;
      &:hover {
        background: #fff;
        color: $brand-color;
      }
    }
  }
}