/*----------------------------------------*\
  TYPOGRAPHY SCAFFOLDING
\*----------------------------------------*/

html {
  font-family: $font-family-default;
  // Use percentage value for root font-size
  // see https://github.com/liip/kanbasu/issues/29
  font-size: 100% * $font-size-default / 16px;
  line-height: $line-height-default;
  color: $text-color;

  // Cleaner font rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/**
 * Links
 */

a {
  color: $link-color;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}


/**
 * Headings
 */

@include headings {
  margin: 1.414em 0 .5em;
  font-weight: inherit;
  line-height: 1.2;
}

h1,
.h1 {
  margin-top: 0;
  font-size: 2.441em;
}

h2,
.h2 {
  font-size: 1.953em;
}

h3,
.h3 {
  font-size: 1.563em;
}

h4,
.h4 {
  font-size: 1.25em;
}

h5,
.h5 {
  font-size: 1em;
}

h6,
.h6 {
  font-size: .8em;
}


/**
 * Paragraphs
 */

p {
  margin-top: 1em;
  margin-bottom: 1.3em;
}


/**
 * Preformatted text
 */

pre,
code {
  font-family: Monaco, monospace;
  font-weight: normal;
  tab-size: 4;

  background-color: #f5f5f5;
}

pre {
  padding: $spacing-unit-default;
  margin: 0 0 2em;
  overflow: auto;

  font-size: rem(14px);

  border-radius: $border-radius-default;
}

code {
  display: inline-block;
  padding: 1px 5px;

  pre & {
    display: block;
    padding: 0;
  }
}


/**
 * Lists
 */

ul,
ol {
  #{$padding-left}: $spacing-unit-default;
  margin: 1em 0;

  ul,
  ol {
    margin: 0;
  }
}

dl {
  @include clearfix;
}

  dt {
    font-weight: bold;
  }

  dd {
    #{$margin-left}: 0;
    margin-bottom: .5em;
  }

  .dl--inline {
    dt {
      float: flip(left, right);
      width: 100px;
    }

    dd {
      @include clearfix;
      #{$margin-left}: calc(100px + #{$spacing-unit-small});
    }
  }


/**
 * Miscellaenous
 */

abbr {
  cursor: help;
}
