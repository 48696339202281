/*----------------------------------------*\
  RIGHT TO LEFT (RTL)
  Inspired by BBC GEL Sass tools
  https://github.com/bbc/gel-sass-tools/
\*----------------------------------------*/

/*
 * Interpolated Properties
 */
$margin-right:       'margin-right';
$margin-left:        'margin-left';
$border-right:       'border-right';
$border-left:        'border-left';
$border-left-width:  'border-right-width';
$border-right-width: 'border-left-width';
$border-left-color:  'border-left-color';
$border-right-color: 'border-right-color';
$border-left-style:  'border-left-style';
$border-right-style: 'border-right-style';
$padding-right:      'padding-right';
$padding-left:       'padding-left';
$right:              'right';
$left:               'left';

/**
 * If the `$rtl` variable is `true` flip the direction of the interpolated properties
 */
@if $rtl {
  $margin-right:       'margin-left';
  $margin-left:        'margin-right';
  $border-right:       'border-left';
  $border-left:        'border-right';
  $border-left-width:  'border-right-width';
  $border-right-width: 'border-left-width';
  $border-left-color:  'border-right-color';
  $border-right-color: 'border-left-color';
  $border-left-style:  'border-right-style';
  $border-right-style: 'border-left-style';
  $padding-right:      'padding-left';
  $padding-left:       'padding-right';
  $right:              'left';
  $left:               'right';
}
