/*----------------------------------------*\
  LIST
\*----------------------------------------*/

.#{$namespace}list,
%list {
  margin: 0;
  padding: 0;

  list-style: none;
}
