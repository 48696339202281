/*----------------------------------------*\
  IMAGES
\*----------------------------------------*/

.img-responsive,
%img-responsive {
  max-width: 100%;
  height: auto;
}

.img-block,
%img-block {
  width: 100%;
  height: auto;
}
