/*----------------------------------------*\
  ALIGN
\*----------------------------------------*/

.align-left {
  #{$margin-right}: auto !important;
}

.align-center {
  #{$margin-left}: auto !important;
  #{$margin-right}: auto !important;
}

.align-right {
  #{$margin-left}: auto !important;
}
