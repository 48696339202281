/*----------------------------------------*\
  DISPLAY
\*----------------------------------------*/

/**
 * HIDE
 */

.hidden,
[hidden] {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

// Generate hidden classes scoped to each breakpoint
@include foreach-breakpoint() {
  .hidden-#{$breakpoint-name}-up {
    display: none !important;
  }
}

@include foreach-breakpoint($breakpoints-desc) {
  .hidden-#{$breakpoint-name}-down {
    display: none !important;
  }
}

// Prevent content to be printed
@media print {
  .hidden-print {
    display: none !important;
  }

  .invisible-print {
    visibility: hidden !important;
  }
}


/**
 * SHOW
 */

// Display a block only when printed
@media not print {
  .visible-print {
    display: none !important;
  }
}


// Provide content to screen readers only
.visible-sr,
%visible-sr {
  @include screen-readers-only;
}
