/*----------------------------------------*\
  EMBED RESPONSIVE
  Credit: Nicolas Gallagher and SUIT CSS.
\*----------------------------------------*/

.#{$namespace}embed-responsive,
%embed-responsive {
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  position: relative;

  > iframe,
  > object,
  > embed {
    @extend %embed-responsive__item;
  }
}

  .#{$namespace}embed-responsive__item,
  %embed-responsive__item {
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    border: 0;
  }

.#{$namespace}embed-responsive--16\/9,
%embed-responsive--16\/9 {
  padding-bottom: 56.25%;
}

.#{$namespace}embed-responsive--4\/3,
%embed-responsive--4\/3 {
  padding-bottom: 75%;
}
