.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.flex-inline {
  display: inline-flex;
  //align-items: center;
}