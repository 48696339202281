/*----------------------------------------*\
  ALIGN RESPONSIVE
\*----------------------------------------*/

@include foreach-breakpoint() {
  .#{$breakpoint-name}-align-left {
    #{$margin-left}: 0 !important;
    #{$margin-right}: auto !important;
  }

  .#{$breakpoint-name}-align-center {
    #{$margin-left}: auto !important;
    #{$margin-right}: auto !important;
  }

  .#{$breakpoint-name}-align-right {
    #{$margin-left}: auto !important;
    #{$margin-right}: 0 !important;
  }
}
