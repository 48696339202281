.plans {
	@extend %flexbox;
	@include flex-flow(wrap);
	padding: 50px 0 30px 0;

	.plan {
		list-style: none;
		padding: 0;
		margin: 0 10px 50px 10px;
		text-align: center;
		border: 1px solid #eee;
		border-radius: 5px;
		box-shadow: 0px 0px 10px #eee;
		width: 100%;

		.highlighted {
			font-size: 1.2em
		}

		.pricing-cta {
			padding: 0;

			a {
				display: block;
				box-sizing: border-box;
				padding: 20px 0;
				border-radius: 0 0 2px 2px;
				border: 0;
			}
		}

		@media #{$desktop} {
			-webkit-flex: 1;
			flex: 1;
		}

		&:nth-child(1) li {
			&:nth-child(1), .button a {
				background: #D3D3D3;
				&:hover {
					background: darken(#D3D3D3, 5%);
				}
				&.darker {
					background: #808080;
					&:hover {
						background: darken(#808080, 5%);
					}
				}
				&.silver {
					background: #C0C0C0;
					&:hover {
						background: darken(#C0C0C0, 5%);
					}
					&.darker {
						background: darken(#C0C0C0, 15%);
						&:hover {
							background: darken(#C0C0C0, 20%);
						}
					}
				}
				&.suva-grey {
					background: #8E8E8E;
					&:hover {
						background: darken(#8E8E8E, 5%);
					}
					&.darker {
						background: darken(#8E8E8E, 15%);
						&:hover {
							background: lighten(darken(#8E8E8E, 15%), 5%);
						}
					}
				}
			}
		}
		&:nth-child(2) li {
			&:nth-child(1), .button a {
				//base bronze https://encycolorpedia.de/cc8e34
				background: #8f8275;
				&:hover {
					background: darken(#8f8275, 5%);
				}
				&.darker {
					background: #9d8569;
					&:hover {
						background: darken(#9d8569, 5%);
					}
				}
			}
		}
		&:nth-child(3) li {
			&:nth-child(1), .button a {
				background: lighten(#daa520, 15%);
				&:hover {
					background: darken(#daa520, 5%);
				}
			}
		}
		&:nth-child(4) li {
			&:nth-child(1), .button a {
				background: #daa520;
				&:hover {
					background: darken(#daa520, 5%);
				}
			}
		}

		li {
			padding: 1.3em 0.5em;
			h3 {
				padding: 0;
				margin: 0;
				color: #fff;
				font-weight: normal;
			}
		}

		.excluded {
			background: darken(#FFE4E1, 5%); //mistyrose
			border-top: 1px dashed #4a4a4a;
			/*
			&:before {
				content: "x";
				width: 3em;
				color: firebrick;
			}*/
		}
		.yearly {
			background: lighten(#63AB62, 7%)!important;
			&:hover {
				background: darken(lighten(#63AB62, 7%), 5%)!important;
			}
		}
	}
}

.faq {
	@media #{$desktop} {
		@include columns(2);
	}
	color: #666;
	div {
		break-inside: avoid;
		padding: 25px 0;
	}

	dt {
		font-weight: bold;
		margin: 0 0 5px 0;
	}

	dd {
		padding: 0;
		margin: 0;

	}
}
