html, body {
	margin: 0;
	padding: 0;
}

strong {
	font-weight: bold;
}

input.checkbox {
	width: 1em;
}

p {
	line-height: 1.5em;
	&.light {
		font-weight: 300;
	}
  &.inline-list {
    margin-left: 2em;
  }
  &.text-right {
    text-align: right;
  }
}

.nav-sticky {
	position: sticky;
	top: 0;
	z-index: 1000;
}

.bg-smoke {
	text-align: center;
	background: #f5f5f5;
	padding: 2em; //16px * 2;
	.button.alt a {
		background: darken(#f5f5f5, 20%);
		&:hover {
			background-color: $secondary-brand-color;
		}
	}
}

.image {
	&.max-400 {
		max-width: 400px;
		margin: auto;
	}
	&.max-150-m0 {
		max-width: 150px;
		margin: 0;
	}
}