/*----------------------------------------*\
  TABLE RESPONSIVE
\*----------------------------------------*/

@mixin table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
}

.#{$namespace}table-responsive,
%table-responsive {
  @include table-responsive;
}

@include foreach-breakpoint($breakpoints-desc) {
  .#{$breakpoint-name}-table-responsive,
  %#{$breakpoint-name}-table-responsive {
    @include table-responsive;
  }
}
