/*----------------------------------------*\
  PUSHER
\*----------------------------------------*/

.#{$namespace}pusher,
%pusher {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

  .#{$namespace}pusher__body,
  %pusher__body {
    max-width: 100%;
    flex: 1 0 auto;
    #{$margin-right}: $spacing-unit-small;
  }

.#{$namespace}pusher--middle,
%pusher--middle {
  align-items: center;
}

.#{$namespace}pusher--bottom,
%pusher--bottom {
  align-items: flex-end;
}

.#{$namespace}pusher--vertical,
%pusher--vertical {
  height: 100%;
  flex-flow: column nowrap;

  .#{$namespace}pusher__body,
  %pusher__body {
    width: 100%;
    #{$margin-right}: 0;
  }
}
