/*----------------------------------------*\
  TEXT
\*----------------------------------------*/

/**
 * Alignment
 */

.text-left {
  text-align: flip(left, right);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: flip(right, left);
}


/**
 * Case
 */

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}


/**
 * Styling
 */

.text-italic {
  font-style: italic;
}

.text-bold {
  font-weight: bold;
}


/**
 * Size
 */

.text-small {
  font-size: $font-size-small;
}

.text-large {
  font-size: $font-size-large;
}


/**
 * Colors
 */

.text-muted {
  color: $muted-color;
}


/**
 * Behavior
 */

.text-nowrap {
  white-space: nowrap;
}

.text-wrap {
  @include wrap-words();
}
